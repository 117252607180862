import {
    Grid,
    makeStyles,
    Theme,
    TextField,
    MenuItem,
    Select,
    InputLabel,
    RadioGroup,
    Radio,
    FormControlLabel,
    Typography,
} from "@material-ui/core"
import { store } from "../../index"
import DashboardClient from "clients/dashboard-client"
import Spinner from "common/spinner"
import { InvoicePeriodStatistic } from "models/invoice-period-statistic"
import React, { useState } from "react"
import { tr } from "utils/translations/translate"
import { ContractDialog } from "../contract-page/contract-dialog"
import { BillingConfiguration } from "models/billing-configuration"
import { InvoicesSendResult } from "models/invoices-send-result"
import { actionCubitSnackbarShow } from "../../redux/cubit-snackbar/cubit-snackbar-actions"
import { Period } from "./period"
import { useDispatch, useSelector } from "react-redux"
import { AppState } from "redux/app/app-store"
import { actionSetLoadingInProgress } from "../../redux/dashboard/period-statistics-actions"
import { actionLoadInvoiceFiles } from "../../redux/invoice-file/invoice-file-table-actions"
import {
    TEXT_ACTIVITY_BASED_INVOICES,
    TEXT_ALL_INVOICE_TYPES,
    TEXT_GENERATE_DIFF_FILE,
    TEXT_GROUPING_TYPE_BY_PRODUCT,
    TEXT_GROUP_BY_BUILDING_NUMBER,
    TEXT_GROUP_BY_CONTRACT,
    TEXT_INVOICE_TYPE,
    TEXT_NO,
    TEXT_PERIODIC_COST_TYPE_NORMAL,
    TEXT_PERIODIC_COST_TYPE_RUNNING,
    TEXT_PERIODIC_INVOICES,
    TEXT_SEND_TO_ERP,
    TEXT_YES,
} from "utils/translations/keys"
import {
    ACTIVITY_BASED_INVOICE_TYPE,
    ALL_INVOICE_TYPES,
    GROUPING_TYPE_BY_BUILDING,
    GROUPING_TYPE_BY_PRODUCT,
    GROUPING_TYPE_NONE,
    PERIODIC_INVOICE_TYPE,
    PERIODIC_TYPE_NORMAL,
    PERIODIC_TYPE_RUNNING,
} from "./dashboard-constants"
import { useUser } from "../../redux/auth/auth-selectors"
import { InvoicingSystemType } from "models/tenant-config"

const useStyles = makeStyles((theme: Theme) => {
    return {
        container: {
            margin: "8px",
        },
        header: {
            padding: "0 62px 0 24px",
        },
        detailsHeader: {
            paddingRight: "38px",
        },
        numericColumn: {
            textAlign: "right",
        },
        expansionPanel: {
            borderRadius: "0!important",
            boxShadow: "none",
            background: "transparent",
            margin: "6px 0 0 0",
            "&::before": {
                display: "none",
            },
            "&.Mui-expanded": {
                margin: "6px 0 0 0",
            },
            "& > .MuiExpansionPanelSummary-root": {
                background: "white",
                "&.Mui-expanded": {
                    minHeight: "initial",
                    margin: "initial",
                },
                "& > .MuiExpansionPanelSummary-content.Mui-expanded": {
                    margin: 0,
                },
            },
            "& > .MuiCollapse-container": {
                marginTop: "2px",
                background: "white",
            },
        },
        tableRow: {
            "&:last-child td": {
                borderBottom: 0,
            },
            "&:hover": {
                cursor: "pointer",
            },
        },
        sendToErp: {
            marginTop: "1rem",
        },
        sendToErpContainer: {
            paddingTop: "2rem",
        },
        subtotals: {
            borderTop: "1px #f0f0f0 solid",
            paddingTop: "0.25rem",
            marginTop: "0.25rem",
        },
        groupingTitle: {},
    }
})

let selectedPeriod: InvoicePeriodStatistic = { period: 0, year: 0, products: [] }
let defaultInvoicesSendResult: InvoicesSendResult = { failureCount: 0, successCount: 0, problemCategories: {} }

export const PeriodAccordions: React.FC<{
    statistics: InvoicePeriodStatistic[]
    isResendEnabled?: boolean
    configuration?: BillingConfiguration
    updateStatistic: (statistic: InvoicePeriodStatistic) => void
}> = props => {
    const dispatch = useDispatch()
    const [isSendToErpRunning, setIsSendToErpRunning] = useState(false)
    const [numberOfPaymentInvoicesToSend, setNumberOfPaymentInvoicesToSend] = useState(0)
    const [inputNumberOfPaymentInvoicesToSend, setInputNumberOfPaymentInvoicesToSend] = useState<string>("")
    const [isSendToErpDialogOpen, setSendToErpDialogOpen] = useState(false)
    const [invoicesSendResult, setInvoicesSendResult] = useState<InvoicesSendResult>(defaultInvoicesSendResult)
    const [selectedPeriodForSending, setSelectedPeriodForSending] = useState<InvoicePeriodStatistic>(selectedPeriod)
    const isUpdatingStatistics = useSelector((state: AppState) => state.invoicePeriodStatistics.isUpdatingStatistics)
    const tenantConfig = useSelector((state: AppState) => state.config.tenantConfig)
    const [invoiceType, setInvoiceType] = useState(0)
    const [periodicCostType, setPeriodicCostType] = useState(PERIODIC_TYPE_RUNNING)
    const [groupingType, setGroupingType] = useState(GROUPING_TYPE_BY_PRODUCT)
    const [diffFileGroupingType, setDiffFileGroupingType] = useState(GROUPING_TYPE_NONE)
    const [generateDiffFileDialogOpen, setGenerateDiffFileDialogOpen] = useState(false)

    const user = useUser()
    const styles = useStyles()

    const generateDiffFile = async () => {
        if (!selectedPeriodForSending) return
        setIsSendToErpRunning(true)
        setGenerateDiffFileDialogOpen(false)
        setIsLoading(true)
        await DashboardClient.generateDiffFile(
            selectedPeriodForSending.year,
            selectedPeriodForSending.period,
            diffFileGroupingType
        ).toPromise()
        setIsLoading(false)
        setIsSendToErpRunning(false)
        dispatch(actionLoadInvoiceFiles())
    }

    const handleGenerateDiffFile = (period) => {
        setGenerateDiffFileDialogOpen(true)
        setSelectedPeriodForSending(period)
    }

    const handleGenereateDiffFileClose = () => {
        setGenerateDiffFileDialogOpen(false)
    }

    const sendToErp = async () => {
        if (selectedPeriodForSending == null) {
            return
        }
        const numberOfInvoices =
            inputNumberOfPaymentInvoicesToSend.length > 0 ? parseInt(inputNumberOfPaymentInvoicesToSend) : undefined
        if (numberOfInvoices !== undefined && numberOfInvoices <= 0) {
            store.dispatch(actionCubitSnackbarShow(["Number of input orders should be more than 0"], "warning"))
            return
        }
        setIsSendToErpRunning(true)
        const invoicesSendResult = await DashboardClient.sendPeriodToErp(
            selectedPeriodForSending.year,
            selectedPeriodForSending.period,
            invoiceType,
            periodicCostType,
            groupingType,
            numberOfInvoices
        ).toPromise()
        setIsSendToErpRunning(false)
        setInvoicesSendResult(invoicesSendResult !== null ? invoicesSendResult : defaultInvoicesSendResult)
        refresh(selectedPeriodForSending)
        dispatch(actionLoadInvoiceFiles())
        if (invoicesSendResult.failureCount === 0) {
            setSendToErpDialogOpen(false)
        }
    }

    const setIsLoading = (isInProgress: boolean) => {
        dispatch(actionSetLoadingInProgress(isInProgress))
    }

    const refresh = async (statistic: InvoicePeriodStatistic) => {
        props.updateStatistic(statistic)
    }

    const handleSendToErpDialogOpen = (period: InvoicePeriodStatistic) => {
        setSelectedPeriodForSending(period)
        setSendToErpDialogOpen(true)

        setNumberOfPaymentInvoicesToSend(
            !props.isResendEnabled ? period.paymentInvoicesNotTransferred ?? 0 : period.paymentInvoiceCount ?? 0
        )

        setInputNumberOfPaymentInvoicesToSend("")
    }

    const handleSendToErpDialogClose = () => {
        setSelectedPeriodForSending(selectedPeriod)
        setSendToErpDialogOpen(false)
        setNumberOfPaymentInvoicesToSend(0)
        setInvoicesSendResult(defaultInvoicesSendResult)
        setInputNumberOfPaymentInvoicesToSend("")
    }

    const onInvoiceTypeChange = value => {
        setInvoiceType(value)
    }

    const handleChangePeriodicType = value => {
        setPeriodicCostType(parseInt(value))
    }

    const isKomtek = tenantConfig
        ? tenantConfig.invoiceSystem.toString() === InvoicingSystemType[InvoicingSystemType.KomTekFile]
        : false

    const handleChangeGroupingType = value => {
        setGroupingType(parseInt(value))
    }

    return (
        <div className={styles.container}>
            <Grid container className={styles.header}>
                <Grid item xs={1}>
                    <strong>{tr("TEXT_TERMIN")}</strong>
                </Grid>
                <Grid item xs={1}>
                    <strong>{tr("TEXT_TERMIN_START")}</strong>
                </Grid>
                <Grid item xs={2}>
                    <strong>{tr("TEXT_TERMIN_END")}</strong>
                </Grid>
                <Grid item xs={2} className={styles.numericColumn}>
                    <strong>{tr("TEXT_DOCUMENTS_NUMBER")}</strong>
                </Grid>
                <Grid item xs={2} className={styles.numericColumn}>
                    <strong>{tr("TEXT_NETAMOUNT")}</strong>
                </Grid>
                <Grid item xs={2} className={styles.numericColumn}>
                    <strong>{tr("TEXT_VATAMOUNT")}</strong>
                </Grid>
                <Grid item xs={2} className={styles.numericColumn}>
                    <strong>{tr("TEXT_AMOUNT")}</strong>
                </Grid>
            </Grid>
            {props.statistics
                .filter(x => !!x.startDate)
                .map((period, index) => (
                    <Period
                        key={index}
                        period={period}
                        index={index}
                        handleSendToErpDialogOpen={handleSendToErpDialogOpen}
                        setIsLoading={setIsLoading}
                        isResendEnabled={props.isResendEnabled}
                        refresh={refresh}
                        isLoading={isUpdatingStatistics}
                        generateDiffFile={() => handleGenerateDiffFile(period)}
                    ></Period>
                ))}
            <ContractDialog
                title={tr(TEXT_GENERATE_DIFF_FILE)}
                open={generateDiffFileDialogOpen}
                handleClose={handleGenereateDiffFileClose}
                handleOk={() => generateDiffFile()}
                okText={tr(TEXT_GENERATE_DIFF_FILE)}
            >
                <Grid item xs={12}>
                    <Typography>{tr(TEXT_GROUPING_TYPE_BY_PRODUCT)}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <RadioGroup
                        aria-label="grouping type"
                        name="groupingType"
                        onChange={e => setDiffFileGroupingType(parseInt(e.target.value))}
                        value={diffFileGroupingType}
                    >
                        <FormControlLabel value={GROUPING_TYPE_NONE} control={<Radio />} label={tr(TEXT_YES)} />
                        <FormControlLabel value={GROUPING_TYPE_BY_BUILDING} control={<Radio />} label={tr(TEXT_GROUP_BY_BUILDING_NUMBER)} />
                    </RadioGroup>
                </Grid>
            </ContractDialog>
            <ContractDialog
                title={tr(TEXT_SEND_TO_ERP)}
                open={isSendToErpDialogOpen}
                handleClose={handleSendToErpDialogClose}
                handleOk={() => sendToErp()}
                okText={tr(TEXT_SEND_TO_ERP)}
            >
                {numberOfPaymentInvoicesToSend > 0 && (
                    <>
                        {invoicesSendResult.successCount === 0 &&
                            invoicesSendResult.failureCount === 0 &&
                            `Run process to send ${numberOfPaymentInvoicesToSend} invoices to the billing system`}
                        {invoicesSendResult.successCount === 0 && invoicesSendResult.failureCount === 0 && (
                            <div>
                                <Grid container spacing={2} className={styles.sendToErpContainer}>
                                    {user?.godMode && (
                                        <Grid item xs={12}>
                                            <TextField
                                                label="Invoice count"
                                                type="number"
                                                InputProps={{ inputProps: { min: 0, style: { textAlign: "right" } } }}
                                                value={inputNumberOfPaymentInvoicesToSend}
                                                onChange={item => {
                                                    setInputNumberOfPaymentInvoicesToSend(item.target.value)
                                                }}
                                            ></TextField>
                                        </Grid>
                                    )}
                                    <Grid item xs={12}>
                                        <InputLabel id="select-invoice-type-input-label">
                                            {tr(TEXT_INVOICE_TYPE)}
                                        </InputLabel>
                                        <Select
                                            labelId="select-invoice-type-input-label"
                                            onChange={e => onInvoiceTypeChange(e.target.value)}
                                            value={invoiceType}
                                        >
                                            <MenuItem key={0} value={ALL_INVOICE_TYPES}>
                                                {tr(TEXT_ALL_INVOICE_TYPES)}
                                            </MenuItem>
                                            <MenuItem key={1} value={PERIODIC_INVOICE_TYPE}>
                                                {tr(TEXT_PERIODIC_INVOICES)}
                                            </MenuItem>
                                            <MenuItem key={2} value={ACTIVITY_BASED_INVOICE_TYPE}>
                                                {tr(TEXT_ACTIVITY_BASED_INVOICES)}
                                            </MenuItem>
                                        </Select>
                                    </Grid>
                                    {isKomtek && invoiceType === PERIODIC_INVOICE_TYPE && (
                                        <>
                                            <Grid item xs={12}>
                                                <RadioGroup
                                                    aria-label="periodic type"
                                                    name="periodicType"
                                                    onChange={e => handleChangePeriodicType(e.target.value)}
                                                    value={periodicCostType}
                                                >
                                                    <FormControlLabel
                                                        value={PERIODIC_TYPE_RUNNING}
                                                        control={<Radio />}
                                                        label={tr(TEXT_PERIODIC_COST_TYPE_RUNNING)}
                                                    />
                                                    <FormControlLabel
                                                        value={PERIODIC_TYPE_NORMAL}
                                                        control={<Radio />}
                                                        label={tr(TEXT_PERIODIC_COST_TYPE_NORMAL)}
                                                    />
                                                </RadioGroup>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography>{tr(TEXT_GROUPING_TYPE_BY_PRODUCT)}</Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <RadioGroup
                                                    aria-label="grouping type"
                                                    name="groupingType"
                                                    onChange={e => handleChangeGroupingType(e.target.value)}
                                                    value={groupingType}
                                                >
                                                    <FormControlLabel
                                                        value={GROUPING_TYPE_BY_PRODUCT}
                                                        control={<Radio />}
                                                        label={tr(TEXT_YES)}
                                                    />
                                                    <FormControlLabel
                                                        value={GROUPING_TYPE_NONE}
                                                        control={<Radio />}
                                                        label={tr(TEXT_GROUP_BY_BUILDING_NUMBER)}
                                                    />
                                                </RadioGroup>
                                            </Grid>
                                        </>
                                    )}
                                </Grid>
                            </div>
                        )}
                    </>
                )}
                {invoicesSendResult.successCount > 0 && (
                    <p>{`${invoicesSendResult.successCount} invoice(s) sent successfully to ERP system!`}</p>
                )}
                {invoicesSendResult.failureCount > 0 && (
                    <span style={{ color: "red" }}>
                        {`${invoicesSendResult.failureCount} invoice(s) failed on sending to ERP system:`}
                        <br />
                        {Object.entries(invoicesSendResult.problemCategories)
                            .slice(0, 5)
                            .map(([key, value]) => (
                                <p>{`(${value.length}) ${key}`}</p>
                            ))}
                        {Object.entries(invoicesSendResult.problemCategories).length > 5 && (
                            <p>
                                .. and {Object.entries(invoicesSendResult.problemCategories).length - 5} more problems
                            </p>
                        )}
                    </span>
                )}
                {isSendToErpRunning && <Spinner />}
            </ContractDialog>
        </div>
    )
}
