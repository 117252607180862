import { CreateInvoicesRequest } from "models/create-invoice"
import { InvoiceCreatePeriod } from "models/invoice-create-period"
import { TestRunInfo } from "models/test-run-info"
import { Observable } from "rxjs"
import { apiUrl, httpGet, httpPost } from "services/httpService"

export default class InvoiceClient {
    public static generate(): Observable<void> {
        return httpGet<void>(apiUrl(`/Invoices/generate`))
    }
    public static publish(): Observable<void> {
        return httpGet<void>(apiUrl(`/Invoices/publish`))
    }
    public static getLastTestRun(): Observable<TestRunInfo> {
        return httpGet<TestRunInfo>(apiUrl(`/Invoices/LastTestRun`))
    }
    public static getCreateInvoicePeriod(contractId: string, refundId: string): Observable<InvoiceCreatePeriod> {
        return httpGet<InvoiceCreatePeriod>(
            apiUrl(`/Invoices/create-invoice-period?contractId=${contractId}&refundedInvoiceId=${refundId}`)
        )
    }
    public static createInvoiceLines(request: CreateInvoicesRequest): Observable<void> {
        return httpPost<void>(apiUrl(`/Invoices/create-invoice-lines`), request)
    }
}
