import { Contract, ContractListItem, UpdateContractSerialNumberResult } from "models/contract"
import { Audit } from "models/audit"
import { ContractVersion } from "models/contract-version"
import { Invoice } from "models/invoice"
import { Observable } from "rxjs"
import { apiUrl, httpGet, httpPut2 } from "services/httpService"
import { ContractSearchItem } from "models/contract-search-item"
import { ContractProductUpdateSet } from "../models/contract-product-update-set"

export default class ContractClient {
    public static getContract(contractId: string): Observable<Contract> {
        return httpGet<Contract>(apiUrl(`/Contracts/${contractId}`))
    }
    public static getContracts(): Observable<Contract[]> {
        return httpGet<Contract[]>(apiUrl(`/Contracts`))
    }
    public static updateProducts(
        contractId: string,
        contractUpdateSets: ContractProductUpdateSet[]
    ): Observable<Contract> {
        return httpPut2<Contract>(apiUrl(`/Contracts/${contractId}/products`), contractUpdateSets)
    }
    public static searchContracts(searchFilter: string): Observable<ContractListItem[]> {
        return httpGet<ContractListItem[]>(apiUrl(`/Contracts/search?searchFilter=${searchFilter}`))
    }
    public static searchContractsAutocomplete(searchFilter: string): Observable<ContractSearchItem[]> {
        return httpGet<ContractSearchItem[]>(apiUrl(`/Contracts/search-autocomplete?searchFilter=${searchFilter}`))
    }
    public static getContractAudit(contractId: string): Observable<Audit[]> {
        return httpGet<Audit[]>(apiUrl(`/Contracts/${contractId}/audit`))
    }
    public static getVersions(contractId: string): Observable<ContractVersion[]> {
        return httpGet<ContractVersion[]>(apiUrl(`/Contracts/${contractId}/versions`))
    }
    public static getInvoices(contractId: string): Observable<Invoice[]> {
        return httpGet<Invoice[]>(apiUrl(`/Contracts/${contractId}/invoices`))
    }
    public static getTestInvoices(contractId: string): Observable<Invoice[]> {
        return httpGet<Invoice[]>(apiUrl(`/Contracts/${contractId}/testinvoices`))
    }
    public static syncContractWithErp(contractId: string): Observable<Contract> {
        return httpPut2<Contract>(apiUrl(`/Contracts/${contractId}/syncWithErp`), null)
    }
    public static updateUseCustomTerms(
        contractId: string,
        useCustomTerms: boolean,
        selectedPeriods: number[],
        periodType: string
    ): Observable<Contract> {
        return httpPut2<Contract>(apiUrl(`/Contracts/updateUseCustomTerms`), {
            useCustomTerms: useCustomTerms,
            contractId: contractId,
            selectedPeriods: selectedPeriods,
            periodType: periodType,
        })
    }

    public static updateSerialNumber(
        contractId: string,
        serialNumber?: string
    ): Observable<UpdateContractSerialNumberResult> {
        return httpPut2<UpdateContractSerialNumberResult>(apiUrl(`/Contracts/updateSerialNumber`), {
            contractId: contractId,
            serialNumber: serialNumber,
        })
    }
}
