import * as keys from "./keys"
export const nb: { [index: string]: string } = {}

nb[keys.FILE_NAME] = "Fil navn"
nb[keys.FILE_TYPE] = "Fil type"
nb[`${keys.FILE_TYPE}_CreditNote`] = "Kreditnota"
nb[`${keys.FILE_TYPE}_ErpExport`] = "ERP eksport"
nb[keys.SENT_DATE] = "Overført dato"
nb[keys.ORDER_FROM] = "Ordre fra"
nb[keys.ORDER_TO] = "Ordre til"
nb[keys.ORDER_SUM] = "Sum"
nb[keys.ORDER_COUNT] = "Antall ordre"
nb[keys.LAST_SENT_FILES_HEADER] = "Siste Overførte filer"
nb[keys.TEXT_MUNICIPALITY] = "kommune"
