import * as keys from "../keys"

export const nb: { [index: string]: string } = {}

nb[keys.TEXT_SAVE] = "Lagre"
nb[keys.TEXT_DASHBOARD] = "Dashbord"
nb[keys.TEXT_PRODUCT] = "Produkt"
nb[keys.TEXT_PRODUCTS_INVOICED] = "Fakturerte produkter"
nb[keys.TEXT_PRODUCTS_REFUNDED] = "Tilbakebetalte produkter"
nb[keys.TEXT_PRODUCTS] = "Produkter"
nb[keys.TEXT_CONTRACT] = "Kontrakter"
nb[keys.TEXT_FILTERS] = "Filter"
nb[keys.TEXT_SEARCH] = "Søk"
nb[keys.TEXT_LANGUAGE] = "Språk"
nb[keys.TEXT_LOGOUT] = "Logg ut"
nb[keys.TEXT_LOGIN] = "Logg inn"
nb[keys.TEXT_CANCEL] = "Avbryt"
nb[keys.TEXT_CODE] = "Kode"
nb[keys.TEXT_PERCENTAGE] = "Prosentdel"
nb[keys.TEXT_ADD_NEW] = "Legg til nytt"
nb[keys.TEXT_EDIT] = "Rediger"
nb[keys.TEXT_DELETE] = "Slett"

nb[keys.TEXT_FILTER_CONTRACT_PLACEHOLDER] = "Filtrer etter matrikkelnummer, adresse, navn eller saksnummer"
nb[keys.TEXT_FILTER_PRODUCT_PLACEHOLDER] = "Filtrer på navn eller beskrivelse"
nb[keys.TEXT_RUN_TEST_BILLING] = "Kjør test fakturering"
nb[keys.TEXT_RUN_BILLING] = "Kjør fakturering"
nb[keys.TEXT_DESCRIPTION] = "Beskrivelse"
nb[keys.TEXT_VALID_FROM] = "Gyldig fra"
nb[keys.TEXT_VALID_TO] = "Gyldig til"
nb[keys.TEXT_PROPERTY_NUMBER] = "Avtalenummer"
nb[keys.TEXT_GNR_BNR] = "Matrikkel nummer"
nb[keys.TEXT_SERIALNUMBER] = "Delnummer"
nb[keys.TEXT_CONTRACTTYPE] = "Type"
nb[keys.TEXT_APARTMENT_NUMBER] = "Leilighetsnummer"
nb[keys.TEXT_POST_ADDRESS] = "Postadresse"
nb[keys.TEXT_POST_CODE] = "Postnummer"
nb[keys.TEXT_ACTIVE_PRODUCTS] = "Aktive produkter"
nb[keys.TEXT_COUNT_ACTIVE_PRODUCTS] = "Antall aktive produkter"
nb[keys.TEXT_OWNER_FULL_NAME] = "Navn"
nb[keys.TEXT_OWNER_RECIPIENT] = "Mottaker"
nb[keys.TEXT_COUNT_RESIDENTIAL_NUMBER] = "Antall boligtall"
nb[keys.TEXT_PRODUCT_DETAILS] = "Produktdetaljer"
nb[keys.TEXT_CREDIT_NOTE] = "Kreditnota"
nb[keys.TEXT_CREATE_CREDIT_NOTE] = "Opprett kreditnota"
nb[keys.TEXT_CREATE_CREDIT_NOTE_FOR_LINES] = "Opprett kreditnota for valgte linjer"
nb[keys.TEXT_INVOICE_LINE_SELECTED] = "fakturalinjer valgt"
nb[keys.TEXT_PRICE] = "Pris"
nb[keys.TEXT_PREVIOUS_VERSIONS] = "tidligere versjon"
nb[keys.TEXT_VERSION] = "Versjon"
nb[keys.TEXT_UPDATED] = "Oppdater"
nb[keys.TEXT_INVOICES] = "Faktura"
nb[keys.TEXT_DATE] = "Dato"
nb[keys.TEXT_PRODUCT_NAME] = "Produktnavn"
nb[keys.TEXT_CUBIT_BILLING] = "Cubit Gebyr"
nb[keys.TEXT_ADDRESS] = "Adresse"
nb[keys.TEXT_STATUS] = "Status"
nb[keys.TEXT_STATUS_ACTIVE] = "Aktiv"
nb[keys.TEXT_STATUS_DRAFT] = "Utkast"
nb[keys.TEXT_STATUS_HISTORIC] = "Historisk"
nb[keys.TEXT_SETTINGS] = "Innstillinger"
nb[keys.TEXT_USER] = "Bruker"
nb[keys.TEXT_WELCOME_TO] = "Velkommen til"
nb[keys.TEXT_PASSWORD] = "Passord"
nb[keys.TEXT_E_MAIL] = "Epost"
nb[keys.TEXT_NAME] = "Navn"
nb[keys.TEXT_NUMBER] = "Nummer"
nb[keys.TEXT_TYPE] = "Type"
nb[keys.TEXT_UNAUTHORIZED] = "Ikke tilgang"
nb[keys.TEXT_FILTER] = "Filtrer"
nb[keys.TEXT_CHIMNEY] = "Røykløp"
nb[keys.TEXT_HOUSING_UNIT] = "Husnummer"
nb[keys.TEXT_AUDIT] = "Tilsyn"

nb[keys.TEXT_REQUIRED] = "Påkrevd"
nb[keys.TEXT_ALREADY_EXISTS] = "Verdi finnes allerede"
nb[keys.TEXT_NOT_FOUND] = "Verdi ikke funnet"
nb[keys.TEXT_INVALID] = "Ugyldig verdi"
nb[keys.TEXT_TYPE_NUMBER] = "Verdi må være et tall"

nb[keys.TEXT_NO_INVOICES] = "Ingen historiske fakturaer"
nb[keys.TEXT_TEST_INVOICES] = "Testfakturaer"
nb[keys.TEXT_TOTAL_PRICE] = "Totalpris"
nb[keys.TEXT_PERIOD] = "Periode"
nb[keys.TEXT_LINE] = "Linje"
nb[keys.TEXT_QUANTITY] = "Antall"
nb[keys.TEXT_LINE_TOTAL] = "Linje totalt"
nb[keys.TEXT_UNIT_PRICE] = "Enhetspris"
nb[keys.TEXT_SUM] = "Sum"
nb[keys.TEXT_SUM_ALL_PRODUCTS] = "Sum alle produkter"
nb[keys.TEXT_LAST_INVOICING] = "Siste faktura"
nb[keys.TEXT_LAST_TEST_INVOICING] = "Siste test faktura"
nb[keys.TEXT_INVOICE_DATE] = "Faktura dato"
nb[keys.TEXT_TEST_DATE] = "Test dato"
nb[keys.TEXT_CONVERT_TO_INVOICE] = "Konverter til faktura"
nb[keys.TEXT_ADD_VAT_CODE] = "Legg til ny MVA kode"
nb[keys.TEXT_VAT_CODE] = "MVA Kode"
nb[keys.TEXT_FRACTION] = "Brøkdel"
nb[keys.TEXT_MVA] = "MVA"
nb[keys.TEXT_PRODUCT_NUMBER] = "Varenummer"
nb[keys.TEXT_PRD_NO] = "Varenummer"
nb[keys.TEXT_INVOICE_RECEIVER] = "Fakturamottaker"
nb[keys.TEXT_OWNER] = "Eier"
nb[keys.TEXT_UTILITY_UNIT] = "Bruksentheter"
nb[keys.TEXT_COUNT_UTILITY_UNITS] = "Antall bruksentheter"
nb[keys.TEXT_UTILITYUNITS_AND_PRODUCTS] = "Bruksentheter og produkter"
nb[keys.TEXT_TERMIN] = "Termin"
nb[keys.TEXT_CASE_NUMBER] = "Saksnr"
nb[keys.TEXT_SHARED_INVOICE] = "delt med"
nb[keys.TEXT_SHARED_REFUND_TXT] = "All lines receive credit note"
nb[keys.TEXT_DATE_SENT] = "Oversendt dato"
nb[keys.TEXT_LOAD_TEMPLATE] = "Last inn prøve"

nb[keys.TEXT_PRODUCTTYPE_CHIMNEY] = "Røykløp"
nb[keys.TEXT_PRODUCTTYPE_HOUSINGUNIT] = "Boenhet"
nb[keys.TEXT_PRODUCTTYPE_AUDIT] = "Tilsyn"
nb[keys.TEXT_PRODUCTSTATUS_ACTIVE] = "Aktiv"
nb[keys.TEXT_PRODUCTSTATUS_DRAFT] = "Draft"
nb[keys.TEXT_PRODUCTSTATUS_HISTORIC] = "Historic"
nb[keys.TEXT_SYNC_ERP] = "Synkroniser med ERP"

nb["TEXT_SEARCH_CATEGORYAddress"] = "Addresse"
nb["TEXT_SEARCH_CATEGORYOwnerName"] = "Navn"
nb["TEXT_SEARCH_CATEGORYMatrikkelNumber"] = "Matrikkel nummer"
nb["TEXT_SEARCH_CATEGORYPostalCode"] = "Postnummer"
nb["TEXT_INVOICE_ADDRESS"] = "Faktura adresse"
nb["TEXT_TERM_SHARE"] = "Terminandel"
nb["TEXT_CREATE_CREDIT_NOTE_AND_COPY"] = "Opprett kreditnota og kopier for ny faktura"
nb["TEXT_REFUND_CONFIRMATION"] = "Er du sikker på at du vil kreditere valgt vare?"
nb["TEXT_CREATE_NEW_INVOICE"] = "Opprett ny faktura"
nb[keys.TEXT_ADD_PRODUCT] = "Legg til nytt produkt"
nb["TEXT_HOUSINGUNIT_INVOICE_LINES"] = "Fakturalinjer knyttet til boenhet"
nb["PRODUCT_NAME"] = "Produkt navn"
nb["TEXT_TOTAL_SUM"] = "Total sum"
nb["TEXT_SELECT_ALL"] = "Velg alle"
nb[keys.TEXT_INVOICES_AND_CONTRACTS_CONFIG] = "Tilpasninger"
nb["TEXT_GENRAL_INVOICES_CONFIG"] = "Fakturaer"
nb[keys.TEXT_GENRAL_REFUNDS_CONFIG] = "Kreditnota"
nb[keys.TEXT_INVOICE_PROCESSING_UPON_IMPORT] = "Send kontrakt til ERP automatisk ved endring"
nb[keys.TEXT_INVOICE_PROCESSING_UPON_IMPORT_2] =
    "Dersom denne deaktiveres må endrede kontrakter manuelt sendes til ERP."

nb["TEXT_INVOICING_ENABLED"] = "Sending til ERP aktivert"
nb[keys.TEXT_SINGLE_INVOICELINE_REFUND_ENABLED] = "Tillatt refusjon på kun én fakturalinje"
nb[keys.TEXT_SINGLE_INVOICELINE_REFUND_ENABLED_2] =
    "Dersom denne aktiveres kan dere opprette kreditnota på kun én fakturalinje i stede for hele fakturaen."
nb[keys.TEXT_ERP_RESEND_ENABLED] = "Send på nytt til ERP aktivert"
nb[keys.TEXT_ALLOW_EDIT_CONTRACT_PRODUCTS] = "Tillat redigering av kontrakter"
nb[keys.TEXT_ALLOW_EDIT_CONTRACT_PRODUCTS_2] =
    "Dersom denne aktiveres blir det mulig å endre på kontrakter i Cubit Faktura."
nb[keys.TEXT_ALLOW_EDIT_CONTRACT_PRODUCTS_3] =
    "Endringer vil ikke være permanente siden det ikke overføres til Cubit Tilsyn."
nb[keys.TEXT_OBS] = "OBS!"

nb[keys.TEXT_ALWAYS_RECREATE_INVOICES_FOR_CURRENT_YEAR_TERMS] =
    "Opprett faktura for inneværende år på nytt ved kontraktsendring"
nb[keys.TEXT_ALWAYS_RECREATE_INVOICES_FOR_CURRENT_YEAR_TERMS_2] =
    "Dersom denne aktiveres vil en endring på en kontrakt føre til at det automatisk opprettes ny faktura og kreditnota gjeldende for hele det inneværende året."
nb[keys.TEXT_ALWAYS_RECREATE_INVOICES_FOR_CURRENT_YEAR_TERMS_3] =
    "Dette kan føre til at feks. kostnader tilknyttet røykløp opprettet i desember blir fakturert for hele året."

nb["TEXT_INVOICE_SYSTEM"] = "Fakturasystem"
nb["TEXT_INVOICE_SYSTEM_CONFIG"] = "Fakturasystem - ERP"
nb["TEXT_TENANT_CONFIGURATION"] = "Innstillinger"
nb["TEXT_INVOICE_INTEGRATION_CONFIG"] = "Fakturasystem konfigurasjon"
nb["TEXT_NONE"] = "Ingen"
nb["TEXT_ERP_VISMA"] = "Visma"
nb["TEXT_ERP_AGRESSO"] = "Agresso"
nb["TEXT_ERP_ISYPA"] = "Isy PA"
nb["TEXT_ERP_AZUREFILE_RINGERIKE"] = "Azure file share til Ringerike"
nb["TEXT_ERP_AZUREFILE_BERGEN"] = "Agresso - Bergen Brannvesen"
nb["TEXT_ERP_AZUREFILE_ISYPA"] = "Azure file share til Isy PA"
nb[keys.TEXT_PERIODCONFIG_TERMS] = "{0} terminer"
nb["TEXT_PERIODCONFIG_NOTERMS"] = "Terminer ikke definert"
nb[keys.TEXT_TERMS] = "Terminer"
nb["TEXT_TERMS_QUANTITY"] = "Antall terminer"
nb["TEXT_START_DATE"] = "Start dato"
nb["TEXT_END_DATE"] = "Slutt dato"
nb["TEXT_LAST_POST_DATE"] = "Siste posteringsdato"
nb["TEXT_TRANSFER_DATE"] = "Overføringsdato dato"
nb["TEXT_TERMIN_START"] = "Termin start"
nb["TEXT_TERMIN_END"] = "Termin slutt"
nb["TEXT_AMOUNT"] = "Beløp"
nb["TEXT_VATAMOUNT"] = "MVA Beløp"
nb["TEXT_NETAMOUNT"] = "NET Beløp"
nb["TEXT_DOCUMENTS_NUMBER"] = "Antall dokumenter"
nb["TEXT_INVOICES_PRODUCT_NUMBER"] = "Antall produkter"
nb["TEXT_REFUNDS_NUMBER"] = "Antall refusjon"
nb[keys.TEXT_SEND_TO_ERP] = "Generer gebyrgrunnlag"

nb["TEXT_REFRESH_PERIOD_STATISTICS"] = "Oppdater"
nb["TEXT_DOWNLOAD_INVOICE_SUMMARY"] = "Få rapport"
nb["TEXT_MIGRATE_DATA"] = "Kjøre migrering"
nb["TEXT_MIGRATE_DATA_HEADER"] = "Migrer kontrakter"
nb["TEXT_MIGRATION_IN_PROGRESS"] = "Laster kontrakter"

nb[keys.TEXT_EDIT_CONTRACT_PRODUCTS] = "Rediger produkter"
nb[keys.TEXT_ADD_CONTRACT_PRODUCT] = "Legg til produkt"
nb[keys.TEXT_PRODUCT_LINE_SELECTED] = "produktlinjer valgt"
nb[keys.TEXT_PRODUCTS_TO_UPDATE] = "produkter å redigere"
nb[keys.TEXT_PRODUCT_BILLING_TYPE] = "Type"
nb[keys.TEXT_PRODUCT_PERIODIC] = "Periodisk"
nb[keys.TEXT_PRODUCT_ACTIVITY_BASED] = "Aktivitetsbasert"
nb[keys.TEXT_VALIDITY] = "Gyldighet"
nb[keys.TEXT_FROM] = "fra"
nb[keys.TEXT_TO] = "til"
nb[keys.TEXT_PRODUCT_INVOICE_PERIODS] = "Faktureres"
nb[keys.TEXT_PRODUCT_EACH_PERIOD] = "Hver termin"
nb[keys.TEXT_PRODUCT_EACH_MAIN_PERIOD] = "Hver hovedtermin"
nb[keys.TEXT_PRODUCT_SELECT_PERIOD] = "Velg terminer"
nb[keys.TEXT_CONTRACT_USE_CUSTOM_TERM] = "Egendefinert termin på alle periodiske produkter"
nb[keys.TEXT_MAIN_PERIOD] = "Hovedtermin"
nb[keys.TEXT_SELECT_PERIOD_COUNT] = "Velg antall terminer"
nb[keys.TEXT_CONTRACT_USE_CUSTOM_TERMS_TOOLTIP] = "Overstyrer hvilke terminer periodiske produkter faktureres"

nb[keys.TEXT_VALIDATION_ALREADY_EXISTS] = "Varenummer finnes allerede"
nb[keys.TEXT_VALIDATION_INVALID_VALUE] = "Ugyldig verdi"
nb[keys.TEXT_VALIDATION_MUST_BE_NUMBER] = "Må være et tall"
nb[keys.TEXT_VALIDATION_NOT_FOUND] = "Ikke funnet"
nb[keys.TEXT_VALIDATION_DATE_FROM_SMALLER] = "Dato fra må være mindre enn dato til"
nb[keys.TEXT_VALIDATION_DATE_INVALID] = "Ugyldig dato"
nb[keys.TEXT_NOT_INVOICED_LINES] = "Ufakturere linjer"

nb[keys.TEXT_ERP_KOMTEK_FILE] = "KomTek"
nb[keys.TEXT_RUN_CONTRACT_MIGRATION] = "Run migration process to migrate new contracts to the billing system."
nb[keys.TEXT_PRODUCT_UPDATED_ICON_TOOLTIP] = "Produktet har blitt endret. Fakturarer må oppdateres"
nb[keys.TEXT_CONTRACT_MAIN_INFO_SERIAL_NUMBER_ERROR] = "Delnummeret eksisterer allerede på denne matrikkelenheten"
nb[keys.TEXT_NOT_ANY] = "Ingen"

nb[keys.TEXT_GENERAL_CONTRACT_CONFIG] = "Kontrakter"

nb[keys.TEXT_USER_NAME] = "Brukernavn"
nb[keys.TEXT_SERVICE_URL] = "Service URL"
nb[keys.TEXT_USER_PASSWORD] = "Passord"
nb[keys.TEXT_COMPANY_ID] = "Company ID"
nb[keys.TEXT_EXTERNAL_SYSTEM] = "External system"
nb[keys.TEXT_CLIENT_NUMBER] = "Klientnummer"
nb[keys.TEXT_DEFAULT_CLIENT_NUMBER] = "Default klientnummer"
nb[keys.TEXT_ADD_CLIENT_NUMBER_PERIOD] = "legg til termin"
nb[keys.TEXT_DOMAIN] = "Domain"
nb[keys.TEXT_APPLICATION_ID] = "Applikasjon ID"
nb[keys.TEXT_CLIENT_ID] = "Klient ID"
nb[keys.TEXT_CLIENT_NUMBER_SECTION_NAME] = "Klientnummer"
nb[keys.TEXT_PRODUCT_GROUP] = "Produkt gruppe"

nb[keys.TEXT_VISMA_FILE] = "Visma fil"
nb[keys.TEXT_KOMTEK_CHANGE_FILE] = "KomTek diff file"
nb[keys.TEXT_DUETT_FILE] = "Duett file"
nb[keys.TEXT_USE_BUILDING_NUMBER] = "Bruk bygningsnummer som serienummer"
nb[keys.TEXT_USE_PERIODIC_FEE_TEXT_FORMAT] = "Gruppering av grunnlag og periodetekst"

nb[keys.TEXT_EMPTY] = "Tom"
nb[keys.TEXT_BUILDING_NUMBER] = "Bygningsnummer"
nb[keys.TEXT_SERIAL_NUMBER] = "Serienummer"
nb[keys.TEXT_CUSTOM_VALUE] = "Egen verdi"
nb[keys.TEXT_CONFIGURATION] = "konfigurasjon"

nb[keys.TEXT_SELECT_CONTRACT_FILE] = "Velg kontraktfil"
nb[keys.TEXT_SELECT_INVOICE_FILE] = "Velg fakturalinjefil"
nb[keys.TEXT_IMPORT_DATE] = "Importdato"
nb[keys.TEXT_MUNICIPALITY_NUMBER] = "Kommunenummer"
nb[keys.TEXT_IMPORT_KOMTEK_FILES] = "Importer"
nb[keys.TEXT_KOMTEK_IMPORT] = "Komtek filimport"

nb[keys.TEXT_GENERATE_DIFF_FILE] = "Generer endringsfil"
nb[keys.TEXT_KOMTEK_IMPORT_TITLE] =
    "Ved å importere grunnlag fra Komtek, overskrives data som allerede ligger i Cubit Gebyr fra den valgte tidsperioden. Handlingen kan ikke angres."

nb[keys.TEXT_IMPORTING] = "Importerer..."
nb[keys.TEXT_DONE] = "Ferdig."
nb[keys.TEXT_INVOICE_TYPE] = "Kostnadstype"

nb[keys.TEXT_ALL_INVOICE_TYPES] = "Alle"
nb[keys.TEXT_PERIODIC_INVOICES] = "Periodiske kostnader"
nb[keys.TEXT_ACTIVITY_BASED_INVOICES] = "Aktivitetsbaserte kostnader"
nb[keys.TEXT_PERIODIC_COST_TYPE_RUNNING] = "Løpende kostnad (har ikke sluttdato)"
nb[keys.TEXT_PERIODIC_COST_TYPE_NORMAL] = "Enkeltkostnad (har satt sluttdato)"
nb[keys.TEXT_GROUPING_TYPE_BY_PRODUCT] = "Grupper like produkter på samme avtale ?"
nb[keys.TEXT_NO] = "Nei"
nb[keys.TEXT_YES] = "Ja"

nb[keys.TEXT_USE_WHOLE_BUILDING_NUMBER] = "Inkluder hele bygningsnummeret"
nb[keys.TEXT_USE_SHORT_BUILDING_NUMBER] = "Inkludert bare siste 4 siffer i bygningsnummer"
nb[keys.TEXT_OVERRIDE_DEFAULT_SERIAL_NUMBER_WITH] = "Erstatt standardverdi 1 med"
nb[keys.TEXT_AGRESSO_MIDT] = "Agresso Standard"
nb[keys.TEXT_REFERENCE_CODE] = "Reference code DR"
nb[keys.TEXT_RESPONSIBLE] = "Responsible"
nb[keys.TEXT_SALESMAN] = "Salesman"
nb[keys.TEXT_VOUCHER_TYPE] = "Voucher type"

nb[keys.TEXT_GROUP_BY_CONTRACT] = "Grupper like produkter på samme avtale"
nb[keys.TEXT_GROUP_BY_BUILDING_NUMBER] = "Grupper ved bygningsnummer"
nb[keys.TEXT_UNIT_CODE] = "Unit code"
