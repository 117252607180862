import * as keys from "./keys"
export const en: { [index: string]: string } = {}

en[keys.FILE_NAME] = "File name"
en[keys.FILE_TYPE] = "File type"
en[`${keys.FILE_TYPE}_CreditNote`] = "Credit note"
en[`${keys.FILE_TYPE}_ErpExport`] = "ERP export"
en[keys.SENT_DATE] = "Sending date"
en[keys.ORDER_FROM] = "Invoice from"
en[keys.ORDER_TO] = "Invoice to"
en[keys.ORDER_SUM] = "Sum"
en[keys.ORDER_COUNT] = "Number of invoices"
en[keys.LAST_SENT_FILES_HEADER] = "Recent invoice files"
en[keys.TEXT_MUNICIPALITY] = "municipality"
