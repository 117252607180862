import React, { useEffect, useState } from "react"
import LayoutDefault from "common/layout/layout-default"
import { tr } from "../../utils/translations/translate"
import {
    TEXT_DASHBOARD,
    TEXT_MIGRATE_DATA,
    TEXT_MIGRATE_DATA_HEADER,
    TEXT_RUN_CONTRACT_MIGRATION,
} from "../../utils/translations/keys"
import TitleToolbar from "common/title-toolbar/title-toolbar"
import Navigation from "common/navigation/navigation"
import Spinner from "common/spinner"
import { RouteComponentProps } from "@reach/router"
import { InvoiceWidget } from "./invoice-widget"
import { makeStyles, Theme, Button } from "@material-ui/core"
import { InvoiceFileWidget } from "./invoice-file-widget"
import DashboardClient from "clients/dashboard-client"
import { PeriodAccordions } from "./period-accordions"
import { InvoicePeriodStatistic } from "models/invoice-period-statistic"
import { ContractDialog } from "../contract-page/contract-dialog"
import { useAuth } from "../../redux/auth/auth-selectors"
import { TestInvoiceWidget } from "./test-invoice-widget"
import InvoiceUpdateProgressNotification from "common/notifications/invoice-update-progress"
import BillingJobClient from "clients/billing-job-client"
import { useDispatch, useSelector } from "react-redux"
import { AppState } from "redux/app/app-store"
import { actionUpdatePeriodStatistics } from "../../redux/dashboard/period-statistics-actions"
import { actionLoadInvoiceUpdateProgress } from "../../redux/billing-job/billing-job-actions"

const useStyles = makeStyles((theme: Theme) => ({
    flex: {
        display: "flex",
    },
    layout: {
        "max-width": "1400px",
    },
}))

const DashboardPage: React.FC<RouteComponentProps> = () => {
    const styles = useStyles()
    const dispatch = useDispatch()

    const [isMigrationRunning, setIsMigrationRunning] = useState(false)
    const [isMigrationDialogOpen, setMigrationDialogOpen] = useState(false)
    const configuration = useSelector((state: AppState) => state.billingConfiguration.billingConfig)
    const statistics = useSelector((state: AppState) => state.invoicePeriodStatistics.items)
    const tenantConfigs = useSelector((state: AppState) => state.config.tenantConfig);
    const invoiceUpdateResponse = useSelector((state: AppState) => state.invoiceUpdateState.invoiceUpdateState)

    const updateStatistic = (statistic: InvoicePeriodStatistic) => {
        dispatch(actionUpdatePeriodStatistics(statistic.year, statistic.period))
    }

    const migrateData = async () => {
        setIsMigrationRunning(true)
        await DashboardClient.migrateData().toPromise()
        setIsMigrationRunning(false)
    }

    const handleMigrationDialogOpen = () => {
        setMigrationDialogOpen(true)
    }

    const handleMigrationDialogClose = () => {
        setMigrationDialogOpen(false)
    }

    
    const updateInvoices = async () => {
        await BillingJobClient.updateInvoices({}).toPromise()
        dispatch(actionLoadInvoiceUpdateProgress())
    }

    const toolbar = (
        <TitleToolbar title={tr(TEXT_DASHBOARD)}>
            {configuration.isMigrationEnabled ? (
                <Button variant="outlined" color="inherit" onClick={() => handleMigrationDialogOpen()}>
                    {tr(TEXT_MIGRATE_DATA_HEADER)}
                </Button>
            ) : null}
        </TitleToolbar>
    )

    return (
        <LayoutDefault navigation={<Navigation />} toolbar={toolbar} showMunicipalities={true}>
            <div className={styles.layout}>
                {tenantConfigs?.isInvoiceUpdateJobEnabled && invoiceUpdateResponse?.hasUpdates && (
                    <InvoiceUpdateProgressNotification
                        invoiceUpdateResponse={invoiceUpdateResponse}
                        showUpdatedEntities={true}
                        updateInvoices={updateInvoices}
                    ></InvoiceUpdateProgressNotification>
                )}
                <PeriodAccordions
                    statistics={statistics}
                    isResendEnabled={configuration.isResendEnabled}
                    configuration={configuration}
                    updateStatistic={updateStatistic}
                ></PeriodAccordions>
                
                <div className={styles.flex}>{configuration.enableFilesDashboard && <InvoiceFileWidget />}</div>
            </div>
            <ContractDialog
                title={tr(TEXT_MIGRATE_DATA_HEADER)}
                open={isMigrationDialogOpen}
                handleClose={handleMigrationDialogClose}
                handleOk={() => migrateData()}
                okText={tr(TEXT_MIGRATE_DATA)}
            >
                {tr(TEXT_RUN_CONTRACT_MIGRATION)}
                {isMigrationRunning && <Spinner />}
            </ContractDialog>
        </LayoutDefault>
    )
}

export { DashboardPage }
